import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ControlMulti, { IControlMultiItem } from '@/components/ui/ControlMulti';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { InfopankCompanyEmtakFilter } from '@/apiTypes/types';
import { transformToControlMultiItem } from '@/components/helpers/searchOptionsToControlMultiItem';
import { debounce } from '@/lib/helpers/debounce';
import { MARKETING_DEBOUNCE_DELAY } from '@/lib/constants';
import { getLayout } from '@/lib/helpers/getLayout';
import { LayoutType } from '@/components/content/MarketingListTool/GeneralFilter';

interface IProps {
  form: IMarketingListToolFilter;
  emtaks?: InfopankCompanyEmtakFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
  toggleItem: (arr: string[], item: string) => string[];
  layout: LayoutType;
  mobileButtons?: React.ReactNode;
}

const searchInItems = (items: IControlMultiItem[], searchString: string): IControlMultiItem[] => {
  const lowerSearch = searchString.toLowerCase();

  return items.reduce<IControlMultiItem[]>((result, item) => {
    const filteredOptions = item.options ? searchInItems(item.options, searchString) : [];
    const itemMatches = item.label.toLowerCase().includes(lowerSearch);

    if (itemMatches || filteredOptions.length > 0) {
      result.push({
        ...item,
        options: filteredOptions,
        searchExpand: true,
      });
    }

    return result;
  }, []);
};

export const getAllChildValues = (options: IControlMultiItem[]) => {
  let values: string[] = [];
  for (const option of options) {
    values.push(option.value);
    if (option.options && option.options.length > 0) {
      values = values.concat(getAllChildValues(option.options));
    }
  }
  return values;
};

export const findOption = (options: IControlMultiItem[], value: string): IControlMultiItem | undefined => {
  for (const option of options) {
    if (option.value === value) {
      return option;
    }
    if (option.options && option.options.length > 0) {
      const found = findOption(option.options, value);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const CompanyEmtaks = (props: IProps) => {
  const { form, emtaks, setForm, toggleItem, layout, mobileButtons } = props;
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [optionsTree, setOptionsTree] = useState<IControlMultiItem[]>([]);
  const { t } = useTranslation();

  const companyEmtaksTree = React.useMemo(() => transformToControlMultiItem(emtaks || []), [emtaks]);

  useEffect(() => {
    const search = () => {
      if (searchKeyword.length > 0) {
        const filtered = searchInItems(companyEmtaksTree, searchKeyword);
        setOptionsTree(filtered);
      } else {
        setOptionsTree(companyEmtaksTree);
      }
    };

    const doSearch = debounce(search, MARKETING_DEBOUNCE_DELAY);

    void doSearch();
  }, [setOptionsTree, companyEmtaksTree, searchKeyword]);

  return (
    <ControlMulti
      id="companyEmtaks"
      placeholder={t('company.filter.fieldActivity')}
      values={form.companyEmtaks}
      options={optionsTree}
      onChange={(value) => {
        const selectedOption = findOption(optionsTree, value);
        if (selectedOption && selectedOption.options) {
          const childValues = getAllChildValues(selectedOption.options);
          const isChecked = form.companyEmtaks.includes(value);
          let newValues;
          if (isChecked) {
            newValues = form.companyEmtaks.filter((v) => ![value, ...childValues].includes(v));
          } else {
            newValues = Array.from(new Set([...form.companyEmtaks, value, ...childValues]));
          }
          setForm({
            ...form,
            companyEmtaks: newValues,
          });
        } else {
          setForm({
            ...form,
            companyEmtaks: toggleItem(form.companyEmtaks, value),
          });
        }
      }}
      searchProps={{
        placeholder: t('company.filter.searchFieldActivity'),
        value: searchKeyword,
        onChange: (e) => {
          setSearchKeyword(e.target.value);
        },
      }}
      layout={getLayout(layout)}
      mobileButtons={mobileButtons}
    />
  );
};
