'use client';

import * as React from 'react';
import { useContext, useState } from 'react';
import Button, { ButtonTheme } from '@/components/ui/Button';
import { AuthContext, getUserOrder } from '@/components/auth/AuthProvider';
import { useOverlay } from '@/components/hooks/useOverlay';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import OverlayAddDebt from '@/components/overlays/AddDebt';
import AddDebtNotLoggedInPaywall from '@/components/overlays/AddDebtNotLoggedInPaywall';

interface IProps {
  title: string;
  theme?: ButtonTheme;
}

const CompanyAddDebtButton: React.FunctionComponent<IProps> = (props) => {
  const { openOverlay } = useOverlay(OverlayKey.lisa_volg);
  const { openOverlay: openDebtPaywall } = useOverlay(OverlayKey.not_logged_in_debt_paywall);
  const { jwt } = useContext(AuthContext);
  const [hasAccess, setHasAccess] = useState(false);

  const onCompanyAddDebtClick = async () => {
    if (!hasAccess) {
      if (jwt) {
        const userOrder = await getUserOrder(jwt);
        if (userOrder?.orderCode) {
          setHasAccess(true);
          openOverlay();
        }
      } else {
        openDebtPaywall();
      }
    } else {
      openOverlay();
    }
  };
  return (
    <React.Fragment>
      <Button
        title={props.title}
        onClick={() => void onCompanyAddDebtClick()}
        size="large"
        theme={props.theme}
      />
      <OverlayAddDebt hasCompanyAutocompleteAccess={hasAccess} />
      <AddDebtNotLoggedInPaywall />
    </React.Fragment>
  );
};

CompanyAddDebtButton.displayName = 'ButtonAddDebt';

export default CompanyAddDebtButton;
