'use client';

import * as React from 'react';
import Button, { ButtonTheme } from '@/components/ui/Button';
import useOfferOverlay from '@/lib/hooks/useOfferOverlay';
import { AskOfferType } from '@/components/ui/ListPackages';

export interface IButtonAskOfferProps {
  title: string;
  analyticsId?: string;
  type?: AskOfferType;
  theme?: ButtonTheme;
}

const ButtonAskOffer: React.FunctionComponent<IButtonAskOfferProps> = (props) => {
  const { open: openOfferForm } = useOfferOverlay();
  return (
    <Button
      title={props.title}
      onClick={() =>
        openOfferForm({
          analyticsId: props.analyticsId,
          type: props.type,
        })
      }
      size="large"
      theme={props.theme}
    />
  );
};

ButtonAskOffer.displayName = 'ButtonAskOffer';

export default ButtonAskOffer;
