'use client';

import * as React from 'react';
import classNames from 'classnames';
import Button, { IButtonProps } from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import ButtonAskOffer from '@/components/content/ButtonAskOffer';
import styles from './styles.module.scss';

export interface IListPackagesItem {
  title: string;
  price: React.ReactNode;
  button?: IButtonProps;
  askOffer?: IAskOfferProps;
  badge?: string;
  features: React.ReactNode[];
}

export type AskOfferType = 'free_period';

interface IAskOfferProps {
  title: string;
  analyticsId?: string;
  type: AskOfferType;
}

export interface IListPackagesLabels {
  labelShowMore: string;
  labelShowLess: string;
  labelShowFeatures: string;
  labelHideFeatures: string;
}

export interface IListPackagesProps extends IListPackagesLabels {
  items: IListPackagesItem[];
}

const ListPackagesItem: React.FunctionComponent<IListPackagesItem & IListPackagesLabels> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const [allVisible, setAllVisible] = React.useState(false);
  return (
    <li>
      <div className={styles['list-packages__heading']}>
        {props.badge && <div className={styles['list-packages__badge']}>{props.badge}</div>}
        <div className={styles['list-packages__title']}>{props.title}</div>
        <div className={styles['list-packages__price']}>{props.price}</div>
        <div className={styles['list-packages__button']}>
          {props.button && !props.askOffer && (
            <Button
              size="large"
              {...props.button}
            />
          )}
          {props.askOffer && !props.button && (
            <ButtonAskOffer
              analyticsId={props.askOffer.analyticsId}
              title={props.askOffer.title}
              type={props.askOffer.type}
            />
          )}
        </div>
      </div>
      <div className={styles['list-packages__content']}>
        <ul
          className={classNames(styles['list-packages__features'], {
            [styles['is-expanded']]: expanded,
            'mobile-hide': !allVisible,
          })}
        >
          {props.features.map((feature, featureIndex) => (
            <li key={featureIndex}>
              <Icon
                kind="check"
                width={16}
                height={16}
              />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <div className={styles['list-packages__tools']}>
          <Button
            theme="link"
            title={`${allVisible ? props.labelHideFeatures : props.labelShowFeatures} (${props.features.length})`}
            onClick={(e) => {
              e.preventDefault();
              setAllVisible(!allVisible);
            }}
            iconRight={allVisible ? 'arrow03-up' : 'arrow03-down'}
            className={classNames('not-mobile-hide', {
              [styles['is-hidden']]: props.features.length < 10,
            })}
          />
          {props.features.length > 10 && (
            <Button
              theme="link"
              title={`${expanded ? props.labelShowLess : props.labelShowMore} (${expanded ? '-' : '+'}${props.features.length - 10})`}
              onClick={(e) => {
                e.preventDefault();
                setExpanded(!expanded);
              }}
              iconRight={expanded ? 'arrow03-up' : 'arrow03-down'}
              className="mobile-hide"
            />
          )}
        </div>
      </div>
    </li>
  );
};

const ListPackages: React.FunctionComponent<IListPackagesProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-packages']}>
      {props.items.map((item) => (
        <ListPackagesItem
          {...item}
          key={item.title}
          labelShowLess={props.labelShowLess}
          labelShowMore={props.labelShowMore}
          labelShowFeatures={props.labelShowFeatures}
          labelHideFeatures={props.labelHideFeatures}
        />
      ))}
    </ul>
  );
};

ListPackages.displayName = 'ListPackages';

export default ListPackages;
