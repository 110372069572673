'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import NonIdealState from '@/components/ui/NonIdealState';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import { initLogin } from '@/lib/sso';
import useOfferOverlay from '@/lib/hooks/useOfferOverlay';

const AddDebtNotLoggedInPaywall: React.FunctionComponent = () => {
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay(OverlayKey.not_logged_in_debt_paywall);
  const { t } = useTranslation();
  const { open: openOfferForm } = useOfferOverlay();
  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={closeOverlay}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          <OverlayTitle onClose={closeOverlay} />
          <NonIdealState
            title={t('components.paywall.addDebt')}
            imageUrl="/img/denied.png"
            buttons={[
              {
                title: t('components.header.login'),
                onClick: () => {
                  closeOverlay();
                  initLogin();
                },
              },
              {
                title: t('components.paywall.button'),
                iconLeft: 'unlock',
                theme: 'primary',
                onClick: () => openOfferForm(),
              },
            ]}
          />
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

AddDebtNotLoggedInPaywall.displayName = 'AddDebtNotLoggedInPaywall';

export default AddDebtNotLoggedInPaywall;
